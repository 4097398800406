@import "../../variables.scss";

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgb(227, 227, 227);
    border-radius: 10px;
    transition: 200ms;
  }

  &--toggle-button {
    cursor: pointer;
    transition: 300ms;
    margin-right: 8px;
  }

  &--dark {
    background-color: rgb(172, 172, 172);
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: rgb(105, 102, 102);
      transition: 200ms;
    }
  }
}

.accordion-content {
  overflow: hidden;
  transition: 200ms;
}

.accordion-open {
  transition: 200ms;
}

.details-modal {
  padding: 35px;

  &__list-title {
    font-weight: 700;
    margin-bottom: -10px;
    margin-top: 5px;
  }

  &__avatar {
    height: 100px;
    width: 100px;
    overflow: hidden;
    border-radius: 15px;

    & img {
      object-fit: cover;
      // object-position: 30% 30%;
      min-width: 100%;
      height: 100%;
    }
  }
}

.disclaimer {
  color: #bbb;
  margin-top: 20px;
  margin-bottom: -20px;
  text-align: center;
}

.form {
  position: relative;
  width: fit-content;
  border: 1px solid #ccc;
  padding: 30px;
  border-radius: 20px;
  margin: 35px auto;
}

.footer-container {
  background-color: rgb(77, 75, 75);
  color: white;
  min-height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 20px;
  text-align: right;

  & a {
    color: white;
    text-decoration: none;

    &:hover {
      color: wheat;
    }
  }

  &__modal-toggle {
    cursor: pointer;

    &:hover {
      color: wheat;
    }
  }
}

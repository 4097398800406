.journals {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-template-areas:
  //   "image1 text1"
  //   "text2 ";

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      "image1 text1"
      "image1 image2"
      "image1 text2";
  }

  &__image {
    mix-blend-mode: multiply;
    width: 60%;

    @media (max-width: 768px) {
      width: 100%;
      margin: 20px 0;
    }
    &__wrapper {
      width: 100%;
      &--centered {
        display: flex;

        justify-content: center;
      }
    }
  }
}

.image1 {
  grid-area: image1;
  @media (max-width: 768px) {
    width: 30%;
    margin: auto;
  }
}
.image2 {
  grid-area: image2;
  margin: auto;
}
.text1 {
  grid-area: text1;
}

.text2 {
  grid-area: text2;
}

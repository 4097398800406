.about-page-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  &__text {
    width: 60%;
    min-width: 250px;
  }

  &__image {
    width: 30%;
    min-width: 250px;
    margin: 20px;

    & img {
      width: 100%;
      border-radius: 12px;
    }
  }
}

.admin-input {
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.admin-button {
  outline: none;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.admin-button-container {
  display: flex;
  justify-content: space-evenly;
  margin: 30px;
}

.create {
  background-color: yellowgreen;
}
.delete {
  background-color: rgb(255, 144, 144);
}

@import "./variables.scss";

body {
  background-color: whitesmoke;
  font-family: "Kanit";
}
.app-container {
  padding: 35px;
  margin-top: 205px;
}

.button {
  text-decoration: none;
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  text-transform: uppercase;
  box-shadow: 3px 3px 10px rgba(200, 200, 200, 0.8);
  margin: 0 !important;
  transition: 300ms;
  &--primary {
    background-color: $primary;
    color: white;

    &:hover {
      background-color: $primaryDark;
    }
  }
}

.container {
  min-height: 100vh;
  animation: fadeIn 2s ease;
  max-width: 1200px;
  margin: auto;
  position: relative;
  padding: 35px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.active {
  font-weight: bold;
}

@media (max-width: 700px) {
  .container {
    margin-bottom: 30px;
    padding: 20px;
  }
  .app-container {
    padding: 10px;
    margin-top: 100px;
  }
}

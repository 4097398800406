.competition {
  &__details {
    display: flex;
    margin-top: 30px;
    &__text {
      flex: 1;
      padding: 30px;
    }

    &__image {
      width: 50%;
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 900px) {
  .competition {
    &__details {
      flex-direction: column-reverse;

      &__image {
        width: 100%;
      }

      &__text {
        padding: 0;
      }
    }
  }
}

@import "../../variables.scss";

.competition-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  &__container {
    text-align: center;
  }

  &__input {
    margin-bottom: 21px;
    margin-top: 5px;
    max-width: 500px;
    width: 100%;
    background-color: white;
  }

  &__disclaimer {
    margin-top: -10px;
    margin-bottom: 20px;
    color: $primary;
  }

  &__radio {
    margin: 12px 0;
    span {
      margin-left: 8px;
    }
  }

  &__radio-group {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dddddd;
    padding-left: 10px;
    margin-bottom: 30px;
  }
}

.proposals-modal {
  padding: 25px;
}

@media (max-width: 700px) {
  .input {
    width: 100%;
  }
}

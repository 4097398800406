.competition-card {
  width: 70%;
  background-color: #eee;
  border-radius: 12px;
  margin: 35px auto;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__button {
    margin-top: 30px;
  }
}

@media (max-width: 700px) {
  .competition-card {
    text-align: center;
    h5 {
      font-size: 1.3rem;
    }
  }
}

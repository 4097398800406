.proposals-form {
  display: flex;
  flex-direction: column;
}

.proposals-modal {
  padding: 25px;
}

.input {
  margin-bottom: 21px;
  width: 500px;
}

@media (max-width: 700px) {
  .input {
    width: 100%;
  }
}

.notfound-container {
  background-color: rgb(100, 0, 100);
  color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;

  & button {
    color: white;
  }
}

.appbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 21px;

  & img {
    height: 120px;
  }
}

.auth-buttons {
  position: absolute;
  right: 35px;
  top: 5px;
}

.nav {
  display: flex;
  justify-content: center;
  position: relative;
}
.inner-nav {
  min-height: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login-button {
  text-decoration: none;
}

.burger-container {
  width: 220px;
}

.burger {
  display: none;
}

@media (max-width: 700px) {
  .burger {
    display: block;
  }
  .donate-button {
    display: none;
  }
  .inner-nav {
    display: none;
  }
  .auth-buttons {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .appbar-container {
    & img {
      height: 60px;
    }
  }
}

.link {
  margin: 5px 10px;
  text-decoration: none;
  font-family: "Kanit";
  font-size: 1.1rem;
  color: rgb(100, 0, 100);
  cursor: pointer;
}

.dropdown-tag {
  color: rgb(100, 0, 100);
  cursor: pointer;
  text-decoration: none;
  font-family: "Kanit";
}

.user-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(100, 0, 100);
  color: white;
  border-radius: 100px;
  cursor: pointer;
  box-shadow: 2px 2px 4px grey;
  width: 40px;
  height: 40px;
  object-fit: cover;
  overflow: hidden;

  & img {
    width: 40px;
  }
}

.past-event-list-item {
  display: flex;
  background-color: #eee;
  border-radius: 12px;
  padding: 8px;
  margin: 8px;

  &:hover {
    background-color: #ccc;
  }
}

@media (max-width: 700px) {
  .past-event-list-item {
    justify-content: space-around;
    h6 {
      font-size: 1rem;
    }
  }
}

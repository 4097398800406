.profile-registration-form {
  display: flex;
  flex-direction: column;
}
.inputs-bar {
  display: flex;
  justify-content: space-evenly;
}

.input-container {
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
}

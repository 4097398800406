.conference-card-container {
  position: relative;
  background-color: #eee;
  padding: 2rem;
  border-radius: 1rem;
  margin: 21px auto;
  // width: 100%;
}

.conference-card {
  margin-top: 16px;
  max-width: 1000px;
  display: flex;

  &__title {
    padding-bottom: 16px;
  }

  &__venue {
    padding-bottom: 16px;
  }

  &__image {
    width: 100px;
    object-fit: cover;
    margin-right: 26px;
    border-radius: 12px;
  }
  &__mobile-image {
    display: none;
  }
}

.more-info-link {
  margin-top: 8px;
}

.buttons-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
}

@media (max-width: 700px) {
  .conference-card-container {
    text-align: center;
    padding: 1rem;

    & a {
      white-space: pre-wrap; /* CSS3 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word; /* Internet Explorer 5.5+ */
    }
    h5 {
      font-size: 1.1rem;
    }
    h4 {
      font-size: 1.5rem;
      text-align: center;
    }
    .conference-card {
      text-align: center;
      & a {
        max-width: 100%;
      }
      &__image {
        display: none;
      }
      &__mobile-image {
        display: flex;
        justify-content: flex-end;
        height: 100px;
      }
    }
  }

  .mobile-image-container {
    width: 100%;
    overflow: hidden;
    border-radius: 100px;
    margin-bottom: 16px;

    & img {
      width: 100%;
    }
  }

  .buttons-container {
    justify-content: space-evenly;
  }

  .more-info-link {
    margin: 16px 0;
  }

  .donations-advice {
    text-align: center;
  }
}

.drawer-container {
  padding: 33px;
  width: 250px;
}

.nav-menu {
  display: flex;
  flex-direction: column;
}

.link {
  margin: 2px 10px;
  text-decoration: none;
  font-family: "Kanit";
  color: rgb(100, 0, 100);
  cursor: pointer;
  margin-bottom: 2rem;
}

.dropdown-tag {
  color: rgb(100, 0, 100);
  cursor: pointer;
  text-decoration: none;
  font-family: "Kanit";
}

.logo {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 700px) {
  .drawer-container {
    padding: 15px;
    width: 260px;
  }
  .close-icon {
    margin-top: -10px;
  }
}

.page-title-container {
  display: flex;
  justify-content: space-between;
}

.sharing-buttons-container {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1050px) {
  .page-title-container {
    text-align: center;
  }

  .page-title {
    font-size: 1.7rem;
  }
}

.tabs {
  &-container {
    margin: 20px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  &__row {
    background-color: #ddd;
    overflow-x: auto;
    display: flex;

    & button {
      border: none;
      background-color: #ddd;
      cursor: pointer;
      border-right: 1px solid #ccc;
      border-radius: 5px 5px 0 0;
      padding: 9px;
      white-space: nowrap;
    }
  }
  &__content {
    padding: 5px;
  }

  &__item--selected {
    background-color: whitesmoke !important;
  }
}

.homepage-container {
  min-height: 100vh;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eee;
  padding: 15px;
  border-radius: 20px;
  margin: 30px 0;
}

.homepage {
  &__intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    &__text {
      flex: 1;
      min-width: 250px;
      padding: 40px;
    }
    &__image {
      width: 50%;
      min-width: 250px;

      & img {
        width: 100%;
      }
    }
  }
}

.update-profile-form {
  display: flex;
  flex-direction: column;
  padding: 35px;
  width: 50%;
}

.update-avatar-form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;
  padding: 35px;
  width: 50%;
}

.profile-pane {
  display: flex;
  justify-content: space-between;
}

.update-profile-pane {
  border: 1px solid #eee;
  border-radius: 20px;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
}

.avatar-container {
  margin: 0 35px;
  border-radius: 250px;
  height: 150px;
  width: 150px;
  overflow: hidden;
  & img {
    width: 100%;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 40px;
  min-width: 300px;
}

.input-container {
  width: 100%;
}

.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

@media (max-width: 700px) {
  .contact-form {
    min-width: 100%;
  }
}

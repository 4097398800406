.header-section {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  &__image {
    border-radius: 12px;
  }
}

.conference-videos-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.conference-video-card {
  margin: 20px;
  width: 400px;
  height: 200px;
  min-width: 400px;
  border-radius: 12px;
  overflow: hidden;
  object-fit: cover;

  iframe {
    width: 100%;
    height: 120%;
    // object-fit: cover;
    // object-position: 10% 30%;
  }
}

.video-title {
  text-align: center;
}

.conference-photos-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 35px;
}

.conference-photos-gallery-image {
  width: 20%;
  margin: 20px;
  word-wrap: break-word;

  & img {
    width: 100%;
  }
}

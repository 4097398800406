@import "../../variables.scss";
.table {
  border-collapse: collapse;
  margin: auto;

  th {
    color: $primary;
    background-color: #ddd;
  }

  td,
  th {
    padding: 5px 10px;
    border: 1px solid #ddd;
  }

  &__title {
    text-align: center;
    color: $primary;
  }
}

@media (max-width: 700px) {
  .table {
    td,
    th {
      padding: 2px;
      font-size: smaller;
    }
  }
}

.member-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.member-card {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  background-color: #eee;
  padding: 12px;
  border-radius: 12px;
  margin: 16px;
  width: 330px;

  &__avatar {
    width: 58px;
    height: 58px;
    border-radius: 50px;
    box-shadow: 2px 2px 4px grey;
    margin-right: 10px;
    overflow: hidden;

    & img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
}

.avatar-placeholder {
  background-color: #ccc;
  width: 58px;
  height: 58px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(100, 0, 100);
  box-shadow: 2px 2px 4px grey;
  margin-right: 16px;
}
.members-list-info {
  padding: 0 4px;
  border-left: 1px solid #ccc;
}

.chip {
  color: white;
  background-color: black;
  border-radius: 12px;
  padding: 1px 6px;
}

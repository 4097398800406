/* Main CSS */
.grid-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.grid-wrapper > div > img {
  // width: 100%;
  // height: 100%;
  // object-fit: cover;
  // border-radius: 5px;
  // cursor: pointer;
}

.grid-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 60px;
  // display: grid;
  // grid-gap: 10px;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  // grid-auto-rows: 100px;
  // grid-auto-flow: dense;
}
// .grid-wrapper .wide {
//   grid-column: span 2;
// }
// .grid-wrapper .tall {
//   grid-row: span 2;
// }
// .grid-wrapper .big {
//   grid-column: span 2;
//   grid-row: span 2;
// }

.image-container {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  margin: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: 300ms;

  &:hover {
    filter: saturate(0.3);
  }
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  &__description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    color: white;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.7);
    // border-radius: 0 0 5px 5px;
  }
}
